import RoleGuard from '@/components/RoleGuard';
import LoadingScreen from '@/components/loadings/LoadingScreen';
import MainLayout from '@/layouts/MainLayout';
import GlobalModals from '@/sections/global-modals';
import { UserPermission } from '@/types/enums/user-permission.enum';
import { ElementType, Suspense, lazy } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';

const Loadable = (Component: ElementType) => (props: any) =>
  (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

export default function PrivateRoutes() {
  return (
    <>
      <Routes>
        <Route path="/" element={<MainLayout />}>
          <Route index element={<HomePage />} />

          <Route path="/auth/password-recovery/:token" element={<ResetPasswordToken />} />
          <Route path="otp/:otpId" element={<Otp />} />

          {/* DASHSBOARDS */}
          <Route path="/dashboards">
            <Route
              path="ranking"
              element={
                <RoleGuard permission={UserPermission.RANKING_READ}>
                  <DashboardRanking />
                </RoleGuard>
              }
            />
            <Route
              path="benchmark"
              element={
                <RoleGuard permission={UserPermission.BENCHMARK_READ}>
                  <DashboardBenchmark />
                </RoleGuard>
              }
            />
            <Route
              path="concurrency"
              element={
                <RoleGuard permission={UserPermission.CONCURRENCY_READ}>
                  <DashboardConcurrency />
                </RoleGuard>
              }
            />
            <Route
              path="pick-ups/:type"
              element={
                <RoleGuard permission={UserPermission.PICK_UP_READ}>
                  <DashboardPickUpOld />
                </RoleGuard>
              }
            />
            <Route
              path="pick-ups-v2/:type"
              element={
                <RoleGuard permission={UserPermission.PICK_UP_READ}>
                  <DashboardPickUp />
                </RoleGuard>
              }
            />
            <Route
              path="emissions/:type"
              element={
                <RoleGuard permission={UserPermission.GOAL_EMISSION_READ}>
                  <DashboardEmission />
                </RoleGuard>
              }
            />
          </Route>

          {/* USERS */}
          <Route path="/users">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.USER_READ}>
                  <UserList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.USER_WRITE}>
                  <UserCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.USER_WRITE}>
                  <UserEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* USERS ROLES */}
          <Route path="/user-roles">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.USER_ROLE_READ}>
                  <UserRoleList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.USER_ROLE_WRITE}>
                  <UserRoleCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.USER_ROLE_WRITE}>
                  <UserRoleEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* HOTELS */}
          <Route path="/hotels">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.HOTEL_READ}>
                  <HotelList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.HOTEL_WRITE}>
                  <HotelCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id/:activeTab"
              element={
                <RoleGuard permission={UserPermission.HOTEL_WRITE}>
                  <HotelEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* GOALS */}
          <Route
            path="goals"
            element={
              <RoleGuard permission={UserPermission.GOAL_READ}>
                <GoalsCrud />
              </RoleGuard>
            }
          />
          {/* CHANNEL GROUPS */}
          <Route path="/channel-groups">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.CHANNEL_GROUP_READ}>
                  <ChannelGroupList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.CHANNEL_GROUP_WRITE}>
                  <ChannelGroupCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.CHANNEL_GROUP_WRITE}>
                  <ChannelGroupEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* CHANNELS */}
          <Route path="/channels">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.CHANNEL_READ}>
                  <ChannelList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.CHANNEL_READ}>
                  <ChannelDetails />
                </RoleGuard>
              }
            />
          </Route>

          {/* SEGMENTS */}
          <Route path="/segments">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.SEGMENT_READ}>
                  <SegmentList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.SEGMENT_WRITE}>
                  <SegmentDetails />
                </RoleGuard>
              }
            />
          </Route>

          {/* LOGS */}
          <Route path="/logs">
            <Route
              path="revenue-jobs"
              element={
                <RoleGuard permission={UserPermission.REVENUE_JOB_READ}>
                  <RevenueJobList />
                </RoleGuard>
              }
            />
            <Route
              path="pick-up-jobs"
              element={
                <RoleGuard permission={UserPermission.PICKUP_JOB_READ}>
                  <PickUpJobsList />
                </RoleGuard>
              }
            />
            <Route
              path="out"
              element={
                <RoleGuard permission={UserPermission.LOGS_READ}>
                  <LogsOutList />
                </RoleGuard>
              }
            />
            <Route
              path="in"
              element={
                <RoleGuard permission={UserPermission.LOGS_READ}>
                  <LogsInList />
                </RoleGuard>
              }
            />
            <Route
              path="rms/action-logs"
              element={
                <RoleGuard permission={UserPermission.LOGS_READ}>
                  <RmsActionLogsList />
                </RoleGuard>
              }
            />
            <Route
              path="rms/fluctuation-logs"
              element={
                <RoleGuard permission={UserPermission.LOGS_READ}>
                  <FluctuationLogsList />
                </RoleGuard>
              }
            />
          </Route>

          {/* REGIONALS */}
          <Route path="/regionals">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.REGIONAL_READ}>
                  <RegionalList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.REGIONAL_WRITE}>
                  <RegionalCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.REGIONAL_WRITE}>
                  <RegionalEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* SEGMENT GROUPS */}
          <Route path="/segment-groups">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.SEGMENT_GROUP_READ}>
                  <SegmentGroupList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.SEGMENT_GROUP_WRITE}>
                  <SegmentGroupCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.SEGMENT_GROUP_WRITE}>
                  <SegmentGroupEdit />
                </RoleGuard>
              }
            />
          </Route>

          {/* ROOMS */}
          <Route path="/rooms">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.ROOM_READ}>
                  <RoomList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.ROOM_READ}>
                  <RoomDetails />
                </RoleGuard>
              }
            />
          </Route>

          {/* RMS */}
          <Route
            path="/rms/fluctuations"
            element={
              <RoleGuard permission={UserPermission.RMS_READ}>
                <FluctuationPage />
              </RoleGuard>
            }
          />
          <Route
            path="/rms/fluctuations-calendar"
            element={
              <RoleGuard permission={UserPermission.RMS_READ}>
                <FluctuationCalendarPage />
              </RoleGuard>
            }
          />
          <Route path="/rms/room-types">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.RMS_READ}>
                  <RoomTypeList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <RoomTypeEdit />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <RoomTypeCreate />
                </RoleGuard>
              }
            />
          </Route>
          <Route path="/rms/fluctuation-rules">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.RMS_READ}>
                  <FluctuationRuleList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <FluctuationRuleEdit />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <FluctuationRuleCreate />
                </RoleGuard>
              }
            />
          </Route>
          <Route path="/rms/rate-plans">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.RMS_READ}>
                  <RatePlanList />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <RatePlanEdit />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.RMS_WRITE}>
                  <RatePlanCreate />
                </RoleGuard>
              }
            />
          </Route>

          {/* CONCURRENT HOTELS */}
          <Route path="/concurrent-hotels">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.CONCURRENCY_READ}>
                  <ConcurrentHotelsList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.CONCURRENCY_WRITE}>
                  <ConcurrentHotelsCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.CONCURRENCY_WRITE}>
                  <ConcurrentHotelsEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* MARKETING GOOGLE ACCOUNTS */}
          <Route path="/google-ads/accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingGoogleAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingGoogleAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingGoogleAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          <Route path="/google-ads/dev-accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingGoogleDevAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingGoogleDevAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingGoogleDevAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* MARKETING META ACCOUNTS */}
          <Route path="/meta/accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingMetaAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          <Route path="/meta/dev-accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingMetaDevAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaDevAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaDevAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* MARKETING META ACCOUNTS */}
          <Route path="/meta/dev-accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingMetaAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingMetaAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* MARKETING TIKTOK ACCOUNTS */}
          <Route path="/tiktok/accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingTikTokAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingTikTokAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingTikTokAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* MARKETING TIKTOK DEV ACCOUNTS */}
          <Route path="/tiktok/dev-accounts">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <MarketingTikTokDevAccountList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingTikTokDevAccountCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.MKT_WRITE}>
                  <MarketingTikTokDevAccountEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* CARTEIRA */}
          <Route path="/wallets">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.WALLET_READ}>
                  <WalletList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.WALLET_WRITE}>
                  <WalletCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.WALLET_WRITE}>
                  <WalletEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* CAMPAIGNS */}
          <Route path="/campaigns">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.MKT_READ}>
                  <CampaignList />
                </RoleGuard>
              }
            />
          </Route>
          {/* HOUSEKEEPING */}
          <Route path="/housekeepers">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.HOUSEKEEPING_READ}>
                  <HouseKeeperList />
                </RoleGuard>
              }
            />
            <Route
              path="create"
              element={
                <RoleGuard permission={UserPermission.HOUSEKEEPING_WRITE}>
                  <HouseKeeperCreate />
                </RoleGuard>
              }
            />
            <Route
              path=":id"
              element={
                <RoleGuard permission={UserPermission.HOUSEKEEPING_WRITE}>
                  <HouseKeeperEdit />
                </RoleGuard>
              }
            />
          </Route>
          {/* HOUSEKEEPING */}
          <Route path="/cleaning-management">
            <Route
              index
              element={
                <RoleGuard permission={UserPermission.HOUSEKEEPING_READ}>
                  <CleaningManagementList />
                </RoleGuard>
              }
            />
          </Route>
          {/* PROFILE */}
          <Route path="/profile" element={<Navigate to="/profile/general" replace />} />
          <Route path="/profile/:activeTab" element={<Profile />} />

          {/* OTHERS */}
          <Route path="403" element={<Page403 />} />
          <Route path="*" element={<Page404 />} />
        </Route>
      </Routes>
      <GlobalModals />
    </>
  );
}

// GENERAL ROUTES
const HomePage = Loadable(lazy(() => import('@/pages/home')));
const Page404 = Loadable(lazy(() => import('@/pages/Page404')));
const Page403 = Loadable(lazy(() => import('@/pages/Page403')));
const ResetPasswordToken = Loadable(lazy(() => import('@/pages/auth/ResetPasswordToken')));
const Otp = Loadable(lazy(() => import('@/pages/otp')));

// Dashboards
const DashboardBenchmark = Loadable(lazy(() => import('@/pages/dashboards/benchmark')));
const DashboardRanking = Loadable(lazy(() => import('@/pages/dashboards/ranking')));
const DashboardPickUpOld = Loadable(lazy(() => import('@/pages/dashboards/pick-up-old')));
const DashboardPickUp = Loadable(lazy(() => import('@/pages/dashboards/pick-up')));
const DashboardConcurrency = Loadable(lazy(() => import('@/pages/dashboards/concurrency')));
const DashboardEmission = Loadable(lazy(() => import('@/pages/dashboards/emission')));

// Profile
const Profile = Loadable(lazy(() => import('@/pages/profile')));

// Users
const UserList = Loadable(lazy(() => import('@/pages/users/list')));
const UserCreate = Loadable(lazy(() => import('@/pages/users/create')));
const UserEdit = Loadable(lazy(() => import('@/pages/users/edit')));

// UserRole
const UserRoleList = Loadable(lazy(() => import('@/pages/user-roles/list')));
const UserRoleCreate = Loadable(lazy(() => import('@/pages/user-roles/create')));
const UserRoleEdit = Loadable(lazy(() => import('@/pages/user-roles/edit')));

const HotelList = Loadable(lazy(() => import('@/pages/hotels/list')));
const HotelCreate = Loadable(lazy(() => import('@/pages/hotels/create')));
const HotelEdit = Loadable(lazy(() => import('@/pages/hotels/edit')));

// Goals
const GoalsCrud = Loadable(lazy(() => import('@/pages/goals')));

// Channels
const ChannelList = Loadable(lazy(() => import('@/pages/channels/list')));
const ChannelDetails = Loadable(lazy(() => import('@/pages/channels/details')));

// Channel Groups
const ChannelGroupList = Loadable(lazy(() => import('@/pages/channel-groups/list')));
const ChannelGroupCreate = Loadable(lazy(() => import('@/pages/channel-groups/create')));
const ChannelGroupEdit = Loadable(lazy(() => import('@/pages/channel-groups/edit')));

// Logs
const LogsOutList = Loadable(lazy(() => import('@/pages/logs/out/list')));
const LogsInList = Loadable(lazy(() => import('@/pages/logs/in/list')));
const RmsActionLogsList = Loadable(lazy(() => import('@/pages/logs/rms/action-logs/list')));
const FluctuationLogsList = Loadable(lazy(() => import('@/pages/logs/rms/fluctuation-logs/list')));
const PickUpJobsList = Loadable(lazy(() => import('@/pages/logs/pick-up-jobs/list')));
const RevenueJobList = Loadable(lazy(() => import('@/pages/logs/revenue-logs/list')));

// Regionals
const RegionalList = Loadable(lazy(() => import('@/pages/regionals/list')));
const RegionalCreate = Loadable(lazy(() => import('@/pages/regionals/create')));
const RegionalEdit = Loadable(lazy(() => import('@/pages/regionals/edit')));

// Rooms
const RoomList = Loadable(lazy(() => import('@/pages/rooms/list')));
const RoomDetails = Loadable(lazy(() => import('@/pages/rooms/details')));

// Segments
const SegmentList = Loadable(lazy(() => import('@/pages/segments/list')));
const SegmentDetails = Loadable(lazy(() => import('@/pages/segments/details')));

// Concurrent hotels
const ConcurrentHotelsList = Loadable(lazy(() => import('@/pages/concurrent-hotels/list')));
const ConcurrentHotelsCreate = Loadable(lazy(() => import('@/pages/concurrent-hotels/create')));
const ConcurrentHotelsEdit = Loadable(lazy(() => import('@/pages/concurrent-hotels/edit')));

// Segment Groups
const SegmentGroupList = Loadable(lazy(() => import('@/pages/segment-groups/list')));
const SegmentGroupCreate = Loadable(lazy(() => import('@/pages/segment-groups/create')));
const SegmentGroupEdit = Loadable(lazy(() => import('@/pages/segment-groups/edit')));

// RMS
const RoomTypeList = Loadable(lazy(() => import('@/pages/rms/room-types/list')));
const RoomTypeCreate = Loadable(lazy(() => import('@/pages/rms/room-types/create')));
const RoomTypeEdit = Loadable(lazy(() => import('@/pages/rms/room-types/edit')));

const FluctuationRuleList = Loadable(lazy(() => import('@/pages/rms/fluctuation-rules/list')));
const FluctuationRuleCreate = Loadable(lazy(() => import('@/pages/rms/fluctuation-rules/create')));
const FluctuationRuleEdit = Loadable(lazy(() => import('@/pages/rms/fluctuation-rules/edit')));

const RatePlanList = Loadable(lazy(() => import('@/pages/rms/rate-plans/list')));
const RatePlanCreate = Loadable(lazy(() => import('@/pages/rms/rate-plans/create')));
const RatePlanEdit = Loadable(lazy(() => import('@/pages/rms/rate-plans/edit')));

const FluctuationPage = Loadable(lazy(() => import('@/pages/rms/fluctuations')));
const FluctuationCalendarPage = Loadable(lazy(() => import('@/pages/rms/fluctuations-calendar')));

// GOOGLE ADS ACCOUNTS:
const MarketingGoogleAccountList = Loadable(lazy(() => import('@/pages/mkt/google/accounts/list')));
const MarketingGoogleAccountCreate = Loadable(
  lazy(() => import('@/pages/mkt/google/accounts/create')),
);
const MarketingGoogleAccountEdit = Loadable(lazy(() => import('@/pages/mkt/google/accounts/edit')));
// GOOGLE ADS DEV ACCOUNTS:
const MarketingGoogleDevAccountList = Loadable(
  lazy(() => import('@/pages/mkt/google/dev-accounts/list')),
);
const MarketingGoogleDevAccountCreate = Loadable(
  lazy(() => import('@/pages/mkt/google/dev-accounts/create')),
);
const MarketingGoogleDevAccountEdit = Loadable(
  lazy(() => import('@/pages/mkt/google/dev-accounts/edit')),
);

// META ACCOUNTS:
const MarketingMetaAccountList = Loadable(lazy(() => import('@/pages/mkt/meta/accounts/list')));
const MarketingMetaAccountCreate = Loadable(lazy(() => import('@/pages/mkt/meta/accounts/create')));
const MarketingMetaAccountEdit = Loadable(lazy(() => import('@/pages/mkt/meta/accounts/edit')));
// META DEV ACCOUNTS:
const MarketingMetaDevAccountList = Loadable(
  lazy(() => import('@/pages/mkt/meta/dev-accounts/list')),
);
const MarketingMetaDevAccountCreate = Loadable(
  lazy(() => import('@/pages/mkt/meta/dev-accounts/create')),
);
const MarketingMetaDevAccountEdit = Loadable(
  lazy(() => import('@/pages/mkt/meta/dev-accounts/edit')),
);

// TIKTOK DEV ACCOUNTS:
const MarketingTikTokDevAccountList = Loadable(
  lazy(() => import('@/pages/mkt/tiktok/dev-accounts/list')),
);
const MarketingTikTokDevAccountCreate = Loadable(
  lazy(() => import('@/pages/mkt/tiktok/dev-accounts/create')),
);
const MarketingTikTokDevAccountEdit = Loadable(
  lazy(() => import('@/pages/mkt/tiktok/dev-accounts/edit')),
);
// Wallets
const WalletList = Loadable(lazy(() => import('@/pages/wallets/list')));
const WalletCreate = Loadable(lazy(() => import('@/pages/wallets/create')));
const WalletEdit = Loadable(lazy(() => import('@/pages/wallets/edit')));

// Campaigns
const CampaignList = Loadable(lazy(() => import('@/pages/mkt/campaigns/list')));

// TIKTOK ACCOUNTS:
const MarketingTikTokAccountList = Loadable(lazy(() => import('@/pages/mkt/tiktok/accounts/list')));
const MarketingTikTokAccountCreate = Loadable(
  lazy(() => import('@/pages/mkt/tiktok/accounts/create')),
);
const MarketingTikTokAccountEdit = Loadable(lazy(() => import('@/pages/mkt/tiktok/accounts/edit')));

//HouseKeeping

const HouseKeeperList = Loadable(lazy(() => import('@/pages/housekeeping/housekeepers/list')));
const HouseKeeperCreate = Loadable(lazy(() => import('@/pages/housekeeping/housekeepers/create')));
const HouseKeeperEdit = Loadable(lazy(() => import('@/pages/housekeeping/housekeepers/edit')));

const CleaningManagementList = Loadable(
  lazy(() => import('@/pages/housekeeping/cleaning-management/list')),
);
