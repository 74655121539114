import { AUTO_REFRESH_SECOND_OPTIONS } from '@/config/auto-refresh';
import {
  DashBenchmarkFilters,
  DashCommonFilters,
  DashConcurrencyFilters,
  DashEmissionFilters,
  DashPickUpFilters,
  DashPickUpOldFilters,
} from '@/types/dashboards/dash-filters';
import { ConcurrentChannel } from '@/types/enums/dashboards/concurrency/dash-concurrent-channel.enum';
import { DashGranularity } from '@/types/enums/dashboards/dash-granularity.enum';
import { DashIndex } from '@/types/enums/dashboards/dash-index.enum';
import { DashPerspective } from '@/types/enums/dashboards/dash-perspective.enum';
import { DashPickUpPerspective } from '@/types/enums/dashboards/pick-up/dash-pick-up-perspective.enum';
import {
  DatePickerPeriod,
  getRangeFromDatepickerPeriod,
} from '@/types/enums/date-picker-period.enum';
import { ReservationStatus } from '@/types/enums/reservation-status.enum';
import { Filters, LogFilters, RmsFilters } from '@/types/general/filters';
import { getDefaultFiltersDashConcurrencyValues } from '@/utils/dashboards/concurrency';
import { getDatesArr } from '@/utils/dates';
import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { addMonths, startOfMonth, subMonths } from 'date-fns';

// Common filters defaults:
const defaultPeriod = DatePickerPeriod.CURRENT_MONTH;
const defaultRange = getRangeFromDatepickerPeriod(defaultPeriod);
const startDate = defaultRange[0];
const endDate = defaultRange[1];

// PickUp filters defaults (OLD):
const defaultPickUpOldPeriod = DatePickerPeriod.PREV_3_DAYS;
const defaultPickUpOldRange = getRangeFromDatepickerPeriod(defaultPickUpOldPeriod);
const defaultPickUpOldPeriodBooking = DatePickerPeriod.CURRENT_MONTH;

// PickUp filters defaults:
const defaultPickUpPeriodEmission = DatePickerPeriod.PREV_3_DAYS;
const defaultPickUpRangeEmission = getRangeFromDatepickerPeriod(defaultPickUpPeriodEmission);
const defaultPickUpPeriodEmissionRitm = DatePickerPeriod.PREV_3_MONTHS;
const defaultPickUpRangeEmissionRitm = getRangeFromDatepickerPeriod(
  defaultPickUpPeriodEmissionRitm,
);
const defaultPickUpPeriodBooking = DatePickerPeriod.NEXT_6_MONTHS;
const defaultDatesBooking = getRangeFromDatepickerPeriod(defaultPickUpPeriodBooking);

// Benchmark filters defaults:
const benchmarkDefaultPeriod = DatePickerPeriod.PREV_MONTH;
const benchmarkDefaultRange = getRangeFromDatepickerPeriod(benchmarkDefaultPeriod);

// Emission filters default:
const emissionDefaultMonth = startOfMonth(new Date());
export const futureMonthsForEmission = 5;
const emissionDefaultBookingPeriods = getDatesArr(
  emissionDefaultMonth,
  addMonths(emissionDefaultMonth, futureMonthsForEmission),
  true,
);

// Concurrency filters default:
const { initialOnlineFilters, initialDailyFilters } = getDefaultFiltersDashConcurrencyValues();

// Logs filters
const logsDefaultPeriod = DatePickerPeriod.CURRENT_MONTH;
const logsDefaultRange = getRangeFromDatepickerPeriod(logsDefaultPeriod);

const initialState: Filters = {
  commonFilters: {
    reloadTrigger: false,
    period: defaultPeriod,
    startDate,
    endDate,
    perspective: DashPerspective.SUM,
    granularity: DashGranularity.MONTH,
    showGoals: false,
    showLastYear: false,
    showLastMonth: false,
    index: DashIndex.REVENUE,
    reservationStatus: ReservationStatus.CONSOLIDATED,
    channels: [],
    segments: [],
    limitResults: 10,
    autoRefreshInSeconds: AUTO_REFRESH_SECOND_OPTIONS[2],
    groups: [],
  },
  pickUpFilters: {
    periodEmission: defaultPickUpPeriodEmission,
    startDateEmission: defaultPickUpRangeEmission[0],
    endDateEmission: defaultPickUpRangeEmission[1],
    periodEmissionRitm: defaultPickUpPeriodEmissionRitm,
    startDateEmissionRitm: defaultPickUpRangeEmissionRitm[0],
    endDateEmissionRitm: defaultPickUpRangeEmissionRitm[1],
    perspective: DashPickUpPerspective.ABSOLUTE,
    granularityEmission: DashGranularity.DAY,
    granularityEmissionRitm: DashGranularity.MONTH,
    granularityBooking: DashGranularity.MONTH,
    periodBooking: defaultPickUpPeriodBooking,
    startDateBooking: defaultDatesBooking[0],
    endDateBooking: defaultDatesBooking[1],
  },
  pickUpOldFilters: {
    // PickUp
    period: defaultPickUpOldPeriod,
    startDate: defaultPickUpOldRange[0],
    endDate: defaultPickUpOldRange[1],
    perspective: DashPickUpPerspective.ABSOLUTE,
    // Ritm
    startDateRitm: startOfMonth(subMonths(new Date(), 3)),
    perspectiveRitm: DashPickUpPerspective.EVOLUTION,
    // General:
    granularity: DashGranularity.MONTH,
    periodBooking: defaultPickUpOldPeriodBooking,
    startDateBooking: defaultDatesBooking[0],
    endDateBooking: defaultDatesBooking[1],
  },
  benchmarkFilters: {
    periodBenchmark: benchmarkDefaultPeriod,
    startDateBenchmark: benchmarkDefaultRange[0],
    endDateBenchmark: benchmarkDefaultRange[1],
  },
  emissionFilters: {
    emissionMonth: emissionDefaultMonth,
    bookingPeriods: emissionDefaultBookingPeriods,
  },
  concurrencyFilters: {
    channel: ConcurrentChannel.BOOKING,
    concurrentHotels: [],
    onlineFilters: initialOnlineFilters,
    dailyFilters: initialDailyFilters,
  },
  rmsFilters: {
    ratePlan: null,
    roomType: null,
    fluctuationRule: null,
    status: 'ALL',
    simulationModeEnabled: false,
  },
  logFilters: {
    periodCreatedAt: logsDefaultPeriod,
    startCreatedAt: logsDefaultRange[0],
    endCreatedAt: logsDefaultRange[1],
    actionLogOperation: 'ALL',
  },
};

const filtersSlice = createSlice({
  name: 'filters',
  initialState,
  reducers: {
    updateDashCommonFilters(state, action: PayloadAction<Partial<DashCommonFilters>>) {
      Object.assign(state.commonFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateDashPickUpFilters(state, action: PayloadAction<Partial<DashPickUpFilters>>) {
      Object.assign(state.pickUpFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateDashPickUpOldFilters(state, action: PayloadAction<Partial<DashPickUpOldFilters>>) {
      Object.assign(state.pickUpOldFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateDashEmissionFilters(state, action: PayloadAction<Partial<DashEmissionFilters>>) {
      Object.assign(state.emissionFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateDashBenchmarkFilters(state, action: PayloadAction<Partial<DashBenchmarkFilters>>) {
      Object.assign(state.benchmarkFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateConcurrencyFilters(state, action: PayloadAction<Partial<DashConcurrencyFilters>>) {
      Object.assign(state.concurrencyFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateRmsFilters(state, action: PayloadAction<Partial<RmsFilters>>) {
      Object.assign(state.rmsFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
    updateLogFilters(state, action: PayloadAction<Partial<LogFilters>>) {
      Object.assign(state.logFilters, action.payload);
      state.commonFilters.reloadTrigger = !state.commonFilters.reloadTrigger;
    },
  },
});

export const {
  updateDashCommonFilters,
  updateDashPickUpFilters,
  updateDashPickUpOldFilters,
  updateDashBenchmarkFilters,
  updateDashEmissionFilters,
  updateConcurrencyFilters,
  updateRmsFilters,
  updateLogFilters,
} = filtersSlice.actions;
export default filtersSlice.reducer;
