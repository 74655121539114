import { combineReducers } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import dashBenchmarkSlice from './slices/dashboards/dashBenchmarkSlice';
import dashConcurrencySlice from './slices/dashboards/dashConcurrencySlice';
import dashEmissionSlice from './slices/dashboards/dashEmissionSlice';
import dashPickUpOldSlice from './slices/dashboards/dashPickUpOldSlice';
import dashPickUpSlice from './slices/dashboards/dashPickUpSlice';
import dashRankingSlice from './slices/dashboards/dashRankingSlice';
import filtersSlice from './slices/filtersSlice';
import homeSlice from './slices/homeSlice';
import legacyDataSlice from './slices/legacyDataSlice';
import logSlice from './slices/logSlice';
import rmsSlice from './slices/rmsSlice';

const rootReducer = combineReducers({
  auth: authSlice,
  filters: filtersSlice,
  logs: logSlice,
  home: homeSlice,
  dashRanking: dashRankingSlice,
  dashPickUp: dashPickUpSlice,
  dashPickUpOld: dashPickUpOldSlice,
  dashBenchmark: dashBenchmarkSlice,
  dashEmission: dashEmissionSlice,
  dashConcurrency: dashConcurrencySlice,
  rms: rmsSlice,
  legacyData: legacyDataSlice,
});

export default rootReducer;
