import Iconify from '@/components/Iconify';
import Label from '@/components/Label';
import Scrollbar from '@/components/Scrollbar';
import RenderOccupancy from '@/components/dashboards/concurrency/RenderOccupancy';
import JsonViewer from '@/components/json-viewer';
import { APP_LOADING_ICON, APP_SYNC_ICON, BEDS_ICON } from '@/config/icons';
import { defaultTableProps } from '@/config/table-config';
import { useNotification } from '@/hooks/useNotification';
import { ConcurrentHotelAvailability, ConcurrentHotelRoom } from '@/types/dashboards/concurrency';
import { ConcurrentHotel } from '@/types/models/concurrent-hotel';
import { formatDate } from '@/utils/dates';
import { formatCurrency } from '@/utils/number';
import { Box, Tooltip, Typography } from '@mui/material';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowParams } from '@mui/x-data-grid';
import { useMemo, useState } from 'react';

const RoomDetailsBox = ({ room }: { room: ConcurrentHotelRoom }) => (
  <Box
    sx={{
      p: 1,
      m: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-end',
    }}
  >
    <Typography noWrap variant="body2">
      {room.name} | <RenderOccupancy occupancy={room.occupancy} />
    </Typography>
    <Typography variant="subtitle1">{formatCurrency(room.price)}</Typography>
  </Box>
);

type GridType = ConcurrentHotelAvailability;

interface Props {
  data: GridType[];
  loading: boolean;
  hotelsLoadingManualData: Record<string, boolean>;
  onManualImport: (concurrentHotelId: Partial<ConcurrentHotel>) => Promise<void>;
}

export default function ConcurrencyDateDetailsTableModal({
  data,
  loading,
  hotelsLoadingManualData,
  onManualImport,
}: Props) {
  const [jwonViewerUrl, setJwonViewerUrl] = useState('');
  const { showInfo } = useNotification();

  const columns = useMemo<GridColDef<GridType>[]>(
    () => [
      {
        field: 'concurrentHotel',
        headerName: 'Hotel',
        headerAlign: 'center',
        flex: 0.5,
        type: 'string',
        valueGetter: ({ row }) => row.concurrentHotel?.name,
      },
      {
        field: 'updatedAt',
        headerName: 'Atualizado em',
        align: 'center',
        headerAlign: 'center',
        width: 150,
        type: 'date',
        valueFormatter: (row) => formatDate(row.value),
      },
      {
        field: 'hasError',
        headerName: 'Erro',
        align: 'center',
        headerAlign: 'center',
        maxWidth: 100,
        type: 'boolean',
        renderCell: (params) =>
          params.value ? <Label color="error">Sim</Label> : <Label color="success">Não</Label>,
      },
      {
        field: 'isAvailable',
        align: 'center',
        headerAlign: 'center',
        headerName: 'Disponível',
        maxWidth: 100,
        type: 'boolean',
        renderCell: (params) =>
          params.value ? <Label color="success">Sim</Label> : <Label color="error">Não</Label>,
      },
      {
        field: 'lowestStandardPrice',
        align: 'right',
        headerName: 'Quarto standard',
        headerAlign: 'right',
        flex: 1,
        sortable: false,
        disableColumnMenu: true,
        renderCell: ({ row, value }) => {
          const room = row.rooms.find(
            (x) => x.occupancy === 2 && x.price === row.lowestStandardPrice
          );

          if (!room) {
            return 'Indisponível';
          }

          return <RoomDetailsBox room={room} />;
        },
      },
      {
        field: 'actions',
        type: 'actions',
        headerName: 'Ações',
        headerAlign: 'center',
        getActions: ({ row }: GridRowParams<GridType>) => [
          <Tooltip arrow title="Atualizar (Booking.com)" key="1" placement="top">
            <GridActionsCellItem
              icon={
                <Iconify
                  icon={
                    hotelsLoadingManualData[row.concurrentHotel?._id!]
                      ? APP_LOADING_ICON
                      : APP_SYNC_ICON
                  }
                  sx={{ width: 16, height: 16 }}
                />
              }
              label="reprocess"
              onClick={() => onManualImport(row.concurrentHotel)}
            />
          </Tooltip>,
          <Tooltip arrow title="Ver todos os quartos" key="2" placement="top">
            <GridActionsCellItem
              icon={<Iconify icon={BEDS_ICON} sx={{ width: 16, height: 16 }} />}
              label="view"
              onClick={() => showInfo(`Em construção...`)}
            />
          </Tooltip>,
          <Tooltip arrow title="Ver detalhes da integração (Booking.com)" key="3" placement="top">
            <GridActionsCellItem
              icon={<Iconify icon="ep:view" sx={{ width: 16, height: 16 }} />}
              label="view"
              onClick={() => setJwonViewerUrl(`/concurrent-hotel-availabilities/${row._id}`)}
            />
          </Tooltip>,
        ],
      },
    ],
    [hotelsLoadingManualData, onManualImport, showInfo]
  );

  return (
    <>
      <Scrollbar sx={{ width: 'auto' }}>
        <DataGrid<GridType>
          {...defaultTableProps}
          paginationMode="client"
          sortingMode="client"
          pageSize={10}
          rows={data}
          loading={loading}
          rowHeight={110}
          columns={columns}
        />
      </Scrollbar>
      <JsonViewer
        url={jwonViewerUrl}
        description="Detalhes da integração"
        onClose={() => setJwonViewerUrl('')}
        api="concurrency"
      />
    </>
  );
}
