export enum DashGroup {
  HOTELS = 'HOTELS',
  SEGMENT_GROUPS = 'SEGMENT_GROUPS',
  SEGMENTS = 'SEGMENTS',
  CHANNEL_GROUPS = 'CHANNEL_GROUPS',
  CHANNELS = 'CHANNELS',
  REGIONALS = 'REGIONALS',
  WALLETS = 'WALLETS',
  CITIES = 'CITIES',
}

export function getDashGroupHumanRead(value: DashGroup, plural = true): string {
  switch (value) {
    case DashGroup.HOTELS:
      return plural ? 'Hotéis' : 'Hotel';
    case DashGroup.SEGMENT_GROUPS:
      return plural ? 'Grupos de segmentos' : 'Grupo de segmentos';
    case DashGroup.SEGMENTS:
      return plural ? 'Segmentos' : 'Segmento';
    case DashGroup.CHANNEL_GROUPS:
      return plural ? 'Grupos de canais' : 'Grupo de canais';
    case DashGroup.CHANNELS:
      return plural ? 'Canais' : 'Canal';
    case DashGroup.REGIONALS:
      return plural ? 'Regionais' : 'Regional';
    case DashGroup.WALLETS:
      return plural ? 'Carteiras' : 'Carteira';
    case DashGroup.CITIES:
      return plural ? 'Cidades' : 'Cidade';
    default:
      return value;
  }
}

/**
 * Retorna o nome do grupo (usado no json de retorno da api) conforme o grupo
 */
export function getDashGroupFieldName(group: DashGroup): string {
  switch (group) {
    case DashGroup.HOTELS:
      return 'hotel';
    case DashGroup.SEGMENTS:
      return 'segment';
    case DashGroup.SEGMENT_GROUPS:
      return 'segmentGroup';
    case DashGroup.CHANNELS:
      return 'channel';
    case DashGroup.CHANNEL_GROUPS:
      return 'channelGroup';
    case DashGroup.REGIONALS:
      return 'regional';
    case DashGroup.WALLETS:
      return 'wallet';
    case DashGroup.CITIES:
      return 'city';
    default:
      throw new Error(`Nome do grupo ${getDashGroupHumanRead(group)} não implementado.`);
  }
}

export function getDashGroupFromFieldName(fieldName: string): DashGroup {
  switch (fieldName) {
    case 'hotel':
      return DashGroup.HOTELS;
    case 'segment':
      return DashGroup.SEGMENTS;
    case 'segmentGroup':
      return DashGroup.SEGMENT_GROUPS;
    case 'channel':
      return DashGroup.CHANNELS;
    case 'channelGroup':
      return DashGroup.CHANNEL_GROUPS;
    case 'regional':
      return DashGroup.REGIONALS;
    case 'wallet':
      return DashGroup.WALLETS;
    case 'city':
      return DashGroup.CITIES;
    default:
      throw new Error(`Nome do campo ${fieldName} não implementado.`);
  }
}
