import { GenericIdName } from '@/types/models/generic-id-name';
import { Segment } from '@/types/models/segment';
import { Chip, FormHelperText, Tooltip, Typography } from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';
import TextField from '@mui/material/TextField';
import { useState } from 'react';

/**
 * Componente Select desacoplado do React Hook Form (multiple)
 */

export interface SelectStaticMultipleProps<T extends GenericIdName> {
  label: string;
  options: T[];
  values: T[];
  onChangeValues: (values: T[]) => void;
  placeholder?: string;
  size?: 'medium' | 'small';
  disabled?: boolean;
  helperText?: string;
  loading?: boolean;
  error?: string;
  disableClearable?: boolean;
  disableCloseOnSelect?: boolean;
  tooltipGetter?: (option: T) => string;
  renderGroupSelect?: boolean;
  disableSelectAll?: boolean;
}

export default function SelectStaticMultiple<T extends GenericIdName>({
  label,
  options = [],
  values,
  onChangeValues,
  placeholder = '',
  size = 'medium',
  disabled = false,
  helperText,
  loading = false,
  error = '',
  disableClearable = false,
  disableCloseOnSelect = true,
  tooltipGetter,
  renderGroupSelect = false,
  disableSelectAll = false,
}: SelectStaticMultipleProps<T>) {
  const [open, setOpen] = useState(false);

  const _disabled = loading || disabled;

  const dataWithGroup = options.filter((val) => (val as unknown as Segment).isGroup);
  const onSetAll = () => {
    onChangeValues(options);
  };

  const onSetAllGroups = () => {
    onChangeValues(dataWithGroup);
  };

  return (
    <Autocomplete
      fullWidth
      multiple
      noOptionsText={'Nenhum resultado.'}
      loadingText="Buscando..."
      open={open}
      onOpen={() => setOpen(true)}
      onClose={() => setOpen(false)}
      disabled={_disabled}
      options={options}
      onChange={(e, val) => onChangeValues(val)}
      size={size}
      value={values}
      disableCloseOnSelect={disableCloseOnSelect}
      loading={loading}
      isOptionEqualToValue={(option, value) => option._id === value._id}
      disableClearable={disableClearable}
      getOptionLabel={(option) => option.name}
      renderTags={(value, getTagProps) =>
        value.map((option, index) => (
          <Tooltip
            arrow
            placement="top"
            title={tooltipGetter !== undefined ? tooltipGetter(option) : ''}
            key={index}
          >
            <Chip
              sx={{
                height: 'auto',
                '& .MuiChip-label': {
                  display: 'block',
                  whiteSpace: 'normal',
                },
              }}
              size={size}
              label={option.name}
              {...getTagProps({ index })}
            />
          </Tooltip>
        ))
      }
      renderInput={(params) => (
        <>
          <TextField
            {...params}
            placeholder={placeholder}
            helperText={error || helperText}
            error={!!error}
            label={label}
            InputProps={{
              ...params.InputProps,
              endAdornment: loading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <div onClick={() => onChangeValues([])}>{params.InputProps.endAdornment}</div>
              ),
            }}
          />
          {!_disabled && !disableSelectAll ? (
            <FormHelperText sx={{ px: 2 }}>
              <Typography
                className="pointer"
                sx={{ textDecoration: 'underline' }}
                variant="caption"
                onClick={onSetAll}
              >
                Marcar todos ({options.length})
              </Typography>
              {renderGroupSelect && (
                <Typography
                  style={{ paddingLeft: 8 }}
                  className="pointer"
                  sx={{ textDecoration: 'underline' }}
                  variant="caption"
                  onClick={onSetAllGroups}
                >
                  Marcar Grupos ({dataWithGroup.length})
                </Typography>
              )}
            </FormHelperText>
          ) : null}
        </>
      )}
    />
  );
}
