import MenuPopover from '@/components/MenuPopover';
import MyAvatar from '@/components/MyAvatar';
import IconButtonAnimate from '@/components/animate/IconButtonAnimate';
import { useSession } from '@/hooks/auth/useSession';
import { useIsMountedRef } from '@/hooks/useIsMountedRef';
import { useAppDispatch } from '@/redux';
import { logoutUser } from '@/redux/slices/authSlice';
import { Box, Divider, MenuItem, Stack, Typography } from '@mui/material';
import { alpha } from '@mui/material/styles';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';

export default function AccountPopover() {
  const { user } = useSession();
  const isMountedRef = useIsMountedRef();
  const [open, setOpen] = useState<HTMLElement | null>(null);
  const dispatch = useAppDispatch();

  const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
    setOpen(event.currentTarget);
  };

  const logout = () => {
    dispatch(logoutUser());
  };

  const handleLogout = () => {
    logout();
    if (isMountedRef.current) {
      setOpen(null);
    }
  };

  return (
    <>
      <IconButtonAnimate
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <MyAvatar />
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={() => setOpen(null)}
        sx={{
          p: 0,
          mt: 1.5,
          ml: 0.75,
          '& .MuiMenuItem-root': {
            typography: 'body2',
            borderRadius: 0.75,
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {user.firstName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {user.email}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          <MenuItem to="/" component={RouterLink} onClick={() => setOpen(null)}>
            Home
          </MenuItem>
          <MenuItem to="/profile" component={RouterLink} onClick={() => setOpen(null)}>
            Minha conta
          </MenuItem>
          <MenuItem to="/profile/password" component={RouterLink} onClick={() => setOpen(null)}>
            Alterar senha
          </MenuItem>
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem onClick={handleLogout} sx={{ m: 1 }}>
          Sair
        </MenuItem>
      </MenuPopover>
    </>
  );
}
