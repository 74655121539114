import { GenericIdName } from '@/types/models/generic-id-name';

export const AUTO_REFRESH_SECOND_OPTIONS: GenericIdName[] = [
  { _id: '10', name: '10 segundos' },
  { _id: '30', name: '30 segundos' },
  { _id: '60', name: '1 minuto' },
  { _id: '120', name: '2 minutos' },
  { _id: '300', name: '5 minutos' },
  { _id: '1800', name: '10 minutos' },
];
